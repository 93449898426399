<template>
  <div class="card" v-if="info" @click="cardClick">
    <div class="card-box" v-if="info.whetheHaveClassManager === 2">
      <p class="tag" v-if="info.hotLabel">{{info.hotLabel}}</p>
      <div class="title">{{info.courseGroupName}}</div>
      <p class="tip red">{{info.subtitle}}</p>
      <p class="tip">{{info.classTime}}</p>
      <div class="teacher-list" v-if="info.teachers">
        <template v-for="(item, index) of info.teachers">
          <div class="teacher-item"  :key="index" v-if="index < 5">
            <img :src="item.headPortrait" :alt="item.teacherName" >
            <p class="name">{{item.teacherName}}</p>
          </div>
        </template>
      </div>
      <div class="info-btn">
        <div class="ib-left">
          <p class="ib-tip" >{{info.costDescription}}</p>
        </div>
        <div class="ib-right"  v-if='btnStateComputed.state === "立即报名"'>
          <span class="price-old" v-if="info.originalPrice">￥{{info.originalPrice}}</span>
          <span class="price-new">￥{{info.currentPrice}}</span>
        </div>
        <div class="ib-right" v-else>
          <span>{{btnStateComputed.state}}</span>
        </div>
      </div>
    </div>
    <div class="card-box" v-else>
      <div class="title">{{info.courseGroupName}}</div>
      <div class="class-list">
        <template v-for="(item, index) of info.flightManagerWebVos">
          <div class="class-item2"  :key="index">
            <img src="@/assets/images/courseGroup/icon1.png">
            <p class="name">{{item.name}}</p>
          </div>
        </template>
      </div>
      <div class="teacher-list2" v-if="info.teachers">
        <span>主讲：</span>
        <template v-for="(item, index) of info.teachers">
          <div class="teacher-item2"  :key="index" v-if="index < 5">
            <p class="name">{{item.teacherName}}</p>
          </div>
        </template>
      </div>
      <div class="info-btn">
        <div class="ib-left">
          <p class="ib-tip" >{{info.costDescription}}</p>
        </div>
        <div class="ib-right"  v-if='btnStateComputed.state === "立即报名"'>
          <span class="price-old" v-if="info.originalPrice">￥{{info.originalPrice}}</span>
          <span class="price-new">￥{{info.currentPrice}}</span>
        </div>
        <div class="ib-right" v-else>
          <span>{{btnStateComputed.state}}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { BTNSTATEFILTER } from '@/config/config'
export default {
  name: "CourseGroupCard",
  props:{
    info: {
      type:Object,
      default:() => {
        return {}
      }
    }
  },
  methods: {
    cardClick() {
      this.$emit('click', this.info)
    }
  },
  computed: {
    btnStateComputed() {
      return BTNSTATEFILTER(this.info)
    }
  }
}
</script>

<style lang="less" scoped>
.card{
  margin:0.4rem 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding:0.55rem 0.3rem 0 0.3rem;
  text-align: left;
  position: relative;
}
.tag{
  position: absolute;
  min-width: 1.81rem;
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 12px;
  text-align: center;
  color: #fff;
  top:0;
  left: 0.3rem;
  background: url('../../assets/images/h5-icon/tag.png') no-repeat;
  background-size: contain;
  user-select: none;
}
.title{
  // height: 0.52rem;
  line-height: 0.52rem;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 8px;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.tip{
  font-size: 14px;
  // height: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #5B6B73;
  margin-bottom: 5px;
}
.red{
  color: #FF484F;
}
.teacher-list{
  display: flex;
  height: 85px;
  border-bottom: 1px solid #f2f2f5;
  
}
.teacher-item{
  width: 62px;
  margin-right: 10px;
}
.teacher-item img{
  display: block;
  width: 44px;
  height: 44px;
  overflow: hidden;
  margin-left:9px;
  margin-bottom: 8px;
  border-radius: 50%;
}
.teacher-item .name{
  width: 28px;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.info-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:1rem;
}
.info-btn .ib-left .ib-tip{
  // height: 0.3rem;
  line-height: 0.4rem;
  margin: 0.3rem 0;
  font-size: 14px;
  font-weight: 400;
  color: #5B6B73;
}
.info-btn .ib-right{
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
}
.info-btn .ib-right span{
  font-size: 14px;
  height:  0.3rem;
  line-height:  0.3rem;
  // padding: 0.3rem 0;
  color: #FF3F47;
  margin-left: 0.17rem;
  font-weight: bold;
}
.info-btn .ib-right .price-new{
  font-size: 16px;
  font-weight: bold;
}
.info-btn .ib-right .price-old{
  font-size: 12px;
  text-decoration: line-through;
}
.class-list{
  width: 100%;
  overflow: hidden;
  padding:10px 0;
}
.class-list .class-item2{
  display: flex;
  align-items: center;
  width:50%;
  float: left;
  height:30px;
  line-height: 30px;
  
}
.class-list .class-item2 img{
  display: block;
  width:0.35rem;
  height:0.35rem;
  margin-right: 5px;
}
.class-list .class-item2 .name{
  font-size: 14px;
  color:#3C7CFC;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.teacher-list2{
  font-size: 14px;
  color:#666666;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f5;
  padding-bottom: 10px;
}
.teacher-list2 .teacher-item2{
  line-height: 30px;
  
}
.teacher-list2 .teacher-item2 .name{
  margin-right: 13px;
}
</style>